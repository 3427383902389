// @flow
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import './interview-grid.scss';

const InterviewGridItem = ({ interview }) => {
  return (
    <Link to={`/interview/${interview.slug}`}>
      <div className="box interview-grid-item">
        <div className="interview-grid-item__avatar">
          <figure className="" style={{ margin: 0 }}>
            <Image
              fluid={interview.avatar.childImageSharp.fluid}
              alt={interview.title}
              style={{ width: 80, height: 80, borderRadius: '50%' }}
            />
          </figure>
        </div>
        <div style={{ marginBottom: 10 }}>
          <h4 className="is-4 mb-2">{interview.name}</h4>
          {/* <p className="subtitle is-6">{interview.position}</p> */}
        </div>
        <p style={{ fontSize: 18 }}>{interview.title}</p>
      </div>
    </Link>
  );
};

export default InterviewGridItem;
