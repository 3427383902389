import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo/seo';
import InterviewGrid from '../components/interview-grid/interview-grid';
import Gradient from '../utils/gradient';
import HeroGradient from '../components/hero/hero-gradient';
import './index.scss';

export default ({ data, location }) => {
  const interviews = data.allInterviewsJson.nodes;

  useEffect(() => {
    const gradient = new Gradient();
    gradient.initGradient('#canvas-gradient2');
  }, []);

  return (
    <Layout>
      <HeroGradient id="canvas-gradient2" />
      <SEO
        title="Explore innovative Web 3.0 startups"
        keywords={[`interviews`, `marketers`, `startups`]}
        imageURL={data.file.publicURL}
      />
      <section className="main-hero hero is-medium">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="is-size-2 is-size-1-tablet" style={{}}>
              <span> Interviews by </span>
              <span
                style={{
                  textDecoration: 'none',
                  position: 'relative',
                  display: 'inline-block',
                }}
              >
                <a className="kyte-underlined" href="https://www.airlyft.one">
                  AirLyft.One
                </a>
              </span>
            </h1>
            <p
              className="is-size-5"
              style={{
                maxWidth: '600px',
                margin: '0 auto',
              }}
            >
              Quick read interviews of startups that are changing the world with
              web3.0 innovations.
            </p>
          </div>
        </div>
      </section>
      <div style={{ margin: '1rem' }}>
        <InterviewGrid items={interviews} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "images/logo-og.png" }) {
      publicURL
    }
    allInterviewsJson(sort: { fields: timestamp, order: DESC }) {
      nodes {
        slug
        name
        title
        avatar {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
