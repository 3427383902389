import React from 'react';
import CardGridItem from './interview-grid-item.component';
import './interview-grid.scss';

const InterviewGrid = ({ items, onClick }) => {
  return (
    <div className="container is-max-widescreen">
      <div className="columns is-4 is-multiline">
        {(items || []).map((item) => (
          <div className="column is-one-third-desktop is-half-tablet is-full-mobile">
            <CardGridItem
              interview={item}
              onClick={(slug) => onClick(slug)}
              key={item.slug}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InterviewGrid;
